.ComponentDemo {
  position: relative;
  margin-bottom: 20px;
}

.ComponentDemo .viewSourceBtn {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
}

.ComponentDemo:hover .viewSourceBtn {
  display: block;
}
